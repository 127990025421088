.footer-bottom-content-box {
    background:#3f0505;
    height: auto;
  
    .footer-bottom-content-box-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 0px;
  
      .footer-social-icons {
        display: flex;
        gap: 30px;
  
        a {
          font-size: 24px;
          text-decoration: none;
          color: white;
  
          &:hover {
            color: white;
          }
        }
      }
  
      .footer-copyright {
        font-size: 18px;
        display:flex !important;
        flex-wrap:wrap !important;
        padding:0px 20px;
        color: white;
        text-align: center;
        .bottom-text{
          text-align: center;
          color: white;

         
        }
  
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }