.pop {
  width: 100svw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.638);
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  .main-popup {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;
    width: 300px;
    border-radius: 10px;
    position: relative;
    .close {
      top: 5px;
      right: 5px;
      background: black;
      height: 50px;
      width: 50px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg {
        font-size: 30px;
        color: white;
      }
    }

    .icons-list {
      display: flex;
      gap: 20px;
      padding: 10px;

      a {
        color: white;
        background-color: var(--accent);
        padding: 10px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.hero {
  height: 100svh;
  position: relative;
  background: rgba(0, 0, 0, 0.527);

  .overlay {
    height: 100%;
    background: url("../Assets/desktop1.webp");
    z-index: -1;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .hero-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    h1 {
      font-size: 50px;
      text-align: center;
      max-width: 30ch;
      line-height: 60px;
      color: white;
    }
  }
  @media only screen and (max-width: 1000px) and (min-width:599px) {
    .hero-cont {
      align-items: center;
      position: absolute !important;
      top: 30% !important;
      left: 0;
      .overlay {
        background: url("../Assets/tab.webp");
      }

    }
  }
  @media only screen and (max-width: 600px) {
  
    .overlay {
      background: url("../Assets/mobile.webp");
      background-position: bottom center !important;
    }
  
}
}

.about,
.cu {
  padding: 50px 0px;
  height: 100%;

  &.cu {
    background: var(--grey);
  }

  .about-cont,
  .cu-cont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.cu-cont {
      flex-direction: row-reverse;
    }
    .left {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .box {
        height: 450px;
        aspect-ratio: 4/3;
        border-radius: 10px;
      }
      .img7 {
        background: url("../Assets/student.webp");
      }
      .img5 {
        background: url("../Assets/university.jpg");
      }
    }
    .right {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
    }
  }
}

.cuf {
  height: 500px;
  .cuf-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .cuf-box-container {
      display: flex;
      align-items: center;
      gap: 20px;
      // flex: 1;
      flex-wrap: wrap;

      .cuf-box {
        padding: 10px;
        box-shadow: var(--shadow);
        border-radius: 10px;
        position: relative;
        height: 280px;
        width: 280px;
        .overlay {
          top: auto;
          left: auto;
          overflow: hidden;
          background-color: #00000054;
          width: 260px;
          height: 260px;

          position: absolute;
          z-index: 2;
        }
        .cuf-img {
          width: 100%;
          height: 100%;
        }
        .img1 {
          background: url("../Assets/self-admission.webp");
        }
        .img2 {
          background: url("../Assets/custom-curicullam.webp");
        }
        .img3 {
          background: url("../Assets/self-exam.webp");
        }
        .img4 {
          background: url("../Assets/self-degree.webp");
        }
        h4 {
          width: 100%;
          position: absolute;
          color: white;
          top: 50%;
          left: 0;
          text-align: center;
          padding: 0px 20px;
          line-height: 20px;
          z-index: 4;
          font-size: 18px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .cuf {
    height: 900px;
    .cuf-cont {
      .cuf-box-container {
        justify-content: center;
      }

      .cu {
        .about-cont,
        .cu-cont {
          flex-direction: row !important;
        }
      }
    }
  }

  .about,
  .cu {
    width: 100%;
    padding: 40px 0px !important;

    .about-cont,
    .cu-cont {
      flex-direction: column !important;
      gap: var(--gap) !important;

      .left {
        width: 100% !important;
        height: 50% !important;
        padding: 0px 5px !important;

        .box {
          width: 100% !important;
        }
      }
      .right {
        width: 100% !important;
        height: 50% !important;

        h2 {
          line-height: 30px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .cuf {
    height: 1300px;
    .cuf-cont {
      .cuf-box-container {
        justify-content: center;
      }
    }
  }

  .about,
  .cu {
    width: 100%;
    padding: 40px 10px !important;

    .about-cont,
    .cu-cont {
      flex-direction: column !important;
      gap: var(--gap) !important;

      .left {
        width: 100% !important;
        height: 50% !important;
      }
      .right {
        width: 100% !important;
        height: 50% !important;

        h2 {
          line-height: 150% !important;
          max-width: 25ch !important;
          text-align: center !important;
        }
      }
    }
  }

  .hero-cont {
    
    align-items: start;
    position: absolute !important;
    top: -25% !important;
    left: 0;
    h1 {
      line-height: 40px !important;
      font-size: 30px !important;
    }
  }

  #root > div > div.parent.cu > div > div.right > h2 {
    line-height: 150% !important;
    max-width: 55ch !important;
    text-align: center !important;
  }
}
