.contact-form {
 
  height: 600px;
  background-color: var(--grey);


  .contact-form-cont {
   padding: 20px 0px;
    width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap);


.conatct-tag{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
}

    

    .form-section {
    width: 100%;
    height: 100%;
   
      padding: 0px 20px;
      display: flex;
      justify-content: center;


      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        gap: var(--gap);

        input {
          width: 100%;
          height: 50px;
          padding: 10px 10px;
          font-size:15px;
          border-radius: var(--rad);
          border: 3px solid transparent;
          outline: none;

          &:focus{
            border: 3px solid var(--accent);
          }

        }
        .number-edu {
          display: flex;
          gap: var(--gap);
          input {
            width: 50%;
          }
        }

        .message {
            height: 100%;
          height: 200px;
        }

        textarea {
            
          padding: 10px 10px;
          font-size: 15px;
          border-radius: var(--rad);
          border: 3px solid transparent;
          outline: none;

          &:focus{
            border: 3px solid var(--accent);
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 600px){
.contact-form{
    height: 850px !important;
    padding: 20px 10px !important;
}
    .contact-form-cont{
        
width: 100% !important;
gap: var(--gap) !important;


.conatct-tag{
    width: 100%;
    text-align: center;

    h2{
      line-height: 150% !important;
      max-width: 25ch !important;
      text-align: center !important;
    }
}
    }
    .number-edu{
flex-direction: column !important;

input{
    width: 100% !important;
}
    }
}